import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStaticQuery, graphql } from "gatsby";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import { StaticImage } from "gatsby-plugin-image";
import { mobileViewBreakpoint } from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },

  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));

const Article = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "casestudy/Staff_Transport_Slider_Image (1).jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Optimize your fleet transport"}
      h1
      banner={
        <StaticImage
          src="../../assets/images/casestudy/Staff_Transport_Slider_Image (1).jpg"
          alt="Bus Driving On A Road"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Strategic Fleet Optimization Utilization" }]}
      metaTitle="Fleet Optimization Management Case Study"
      metaDescription="Check out the fleet management case study. Learn strategic fleet utilization to operate your staff transport with scalability and efficiency."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography component="h2" customVariant={"h2Bold"}>
            Connected tech that simplifies fleet management
          </Typography>

          <Typography customVariant={"h5Regular"}>
            Use our expertise to plan, implement, and operate a truly connected
            technology stack. Our systems bring new efficiencies to how you
            operate, monitor, and manage your precious fleet assets.
          </Typography>
        </div>

        <Summary
          title={"Overview"}
          isFlipped={false}
          // eslint-disable-next-line react/jsx-no-undef
          image={
            <StaticImage
              src="../../assets/images/casestudy/Staff_Transport_Overview_Image-1.jpg"
              alt="corporate transportation"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Our team can unite your transport and{" "}
              <a href="https://motusone.com/logistics/">logistics</a> plans into
              one centralized platform. We use handhelds, OBD 2 devices, and
              web-based management dashboards that deliver powerful and
              immediate benefits – automated schedules and route assignation;
              service planning and implementation; vehicle tracking and
              monitoring, and much more.
            </>
          }
        />

        <Summary
          title={"Technology"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/Staff_Transport_Elements_Image-1.jpg"
              alt="Picture Of Buses Parked At A Depot"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Our web-based technology enables:
              <ul>
                <li>Comprehensive fleet management</li>
                <li>Live staff updates </li>
                <li>Driver analytics </li>
                <li>Staffing and personnel management </li>
                <li>Geo-fencing for vehicles and staff </li>
                <li>Automated passenger count and identification </li>
                <li>Map view and navigation </li>
                <li>Next-vehicle arrival times </li>
                <li>Fuel-consumption metrics </li>
                <li>Route optimization</li>
              </ul>
            </>
          }
        />

        <Summary
          title={"Outcomes"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/Staff_Transport_Outcomes_Image-1.jpg"
              alt="Bus Arriving At Bus Stop"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Keep track of your staff and passengers with real-time data using
              scanned ID cards. It’s a complete and accurate system that
              monitors driver and passenger IDs, numbers, and routes, so you can
              manage your people and assets with radical new ease and
              effectiveness.
            </>
          }
        />

        <Summary
          title={"Legacy"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/Staff_Transport_Legacy_Image-1.jpg"
              alt="Bus Exit Door For Passengers"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              By implementing systems that track and record the movement,
              direction, and status of vehicles, you can focus on what matters –
              project delivery. We can also tie your monitoring systems into
              web-based management dashboards and handhelds. That enables your
              entire team – on the ground and back-office – to collaborate and
              liaise with optimum efficiency.
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
